import React, { useState } from 'react';

import useAddress from '../../../../Hooks/useAddress';

import ModalPrompt from './ModalPrompt';
import { MODALPROMPT } from './config';
import useModalPrompt from './hooks/useModalPrompt';
import { AddressSelector } from '../../Address';

import { AddressItemDetails } from '../../../../Types';

const ListAddress = () => {
  const { setActiveAddress } = useAddress();
  const { showModalPrompt, hideModalPrompt } = useModalPrompt();
  const [data, setData] = useState<{
    storeId: string;
    navigation: any;
    route: any;
  }>();
  const { storeId, navigation, route } = data || {};

  const onPressActiveAddress = (address: AddressItemDetails) =>
    setActiveAddress({ ...address, storeId: storeId || '' });

  const onSelectedSavedAddressAsEdit = (
    index: number,
    item: AddressItemDetails
  ) => {
    hideModalPrompt(MODALPROMPT.listAddress, {});
    showModalPrompt(MODALPROMPT.addressDetails, {
      status: 'edit',
      selectedAddress: {
        ...item,
        index: item.id,
        latitude: item?.geo_json_point.coordinates[1],

        longitude: item?.geo_json_point.coordinates[0],

        storeId: storeId,
      },
      navigation: navigation,
      route: route,
    });
  };
  const onAddNewAddress = () => {
    hideModalPrompt(MODALPROMPT.listAddress, {});
    showModalPrompt(MODALPROMPT.addressDetails, {
      status: 'new',
      selectedAddress: {},
      navigation: navigation,
      route: route,
    });
  };
  return (
    <ModalPrompt
      id={MODALPROMPT.listAddress}
      title={'Saved Addresses'}
      hasHeader={true}
      hasHeaderLine={true}
      closeOnDocumentClick={false}
      onBeforeShow={setData}
    >
      <AddressSelector
        storeId={storeId}
        onSelectedSavedAddress={onPressActiveAddress}
        onSelectedSavedAddressAsEdit={onSelectedSavedAddressAsEdit}
        addNewAddress={onAddNewAddress}
      />
    </ModalPrompt>
  );
};

export default ListAddress;
