import React, { Fragment, useEffect } from 'react';
import { View, ActivityIndicator } from 'react-native';
import { Text, useTheme } from '@ui-kitten/components';
import StyleSheet from 'react-native-media-query';
import PropTypes from 'prop-types';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashPick from 'lodash/pick';

import constants from '../../Config/constants';
import { getCartTerm } from '../../Helper/RemoteConfig';
import Service from '../../Service';
import ThemeStyle from '../../Theme/styles';
import ThemeColor from '../../Theme/colors';
import BreakPoints from '../../Theme/styles/breakpoints';

import Button from '../Button';
import LimitReached from './LimitReached';
import Loader from './Loader';
import Selector from './Selector';

function QuantitySelector({
  isUpdate,
  mealPlan,
  renderSelector,
  onLimitReached,
  ...rest
}) {
  const store_theme = useTheme();

  const webProps = lodashPick(rest, [
    'totalPrice',
    'isAvailable',
    'disabled',
    'addToCartPress',
  ]);
  const limitReachedProps = lodashPick(rest, [
    'productId',
    'storeId',
    'stocks',
    'onReschedule',
  ]);
  const selectorProps = lodashPick(rest, [
    'disabled',
    'loading',
    'value',
    'valueOnCart',
    'onChange',
  ]);

  const { stocks } = limitReachedProps;
  const { value, valueOnCart, onChange } = selectorProps;
  const hasStockLimit = stocks?.remainingStock !== -1;
  const isMealPlan = !lodashIsEmpty(mealPlan);
  const totalSelectorValue = isUpdate ? value : value + (valueOnCart || 0); // incase valueOnCart is undefined, make it 0 it just means no same item on cart yet
  // maxQty on add to cart, just use the remainingStock since its already consider the stocks reduce the item in the cart also item that is in the same category
  // maxQty on update cart, use the remainingStock + valueOnCart because remainngStock consider the cart item and item that is in same category
  const maxQty = isUpdate
    ? stocks?.remainingStock + valueOnCart
    : stocks?.remainingStock;
  const isMaxQty = hasStockLimit ? value >= maxQty : false;
  const isMealPlanMaxQty = value >= mealPlan?.limit;
  const isMaxReached = isMealPlan ? isMealPlanMaxQty : isMaxQty;

  if (onLimitReached) {
    useEffect(() => {
      if (!stocks?.isLoading) {
        onLimitReached?.(isMaxReached);
      }
    }, [stocks?.isLoading, isMaxReached]);
  }

  const _changeCounter = (changeCount) => () => onChange(value + changeCount);

  const _renderSelector = () => {
    if (stocks?.isLoading) {
      return <Loader />;
    } else {
      const isMealPlanCannotAdd = mealPlan?.limit === 0; // if meal plan limit is 0, it means this item cannot be add to cart anymore
      const isNonMealPlanCannotAdd = stocks?.remainingStock === 0; // if not meal plan and remainingStock left is 0
      const displayZeroValue =
        !isUpdate && (isMealPlanCannotAdd || isNonMealPlanCannotAdd);
      return (
        <Selector
          {...selectorProps}
          value={displayZeroValue ? 0 : value}
          isMaxReached={isMaxReached}
          onChange={_changeCounter}
        />
      );
    }
  };

  if (constants.isWeb) {
    const buttonText = `${
      isUpdate ? 'Update' : 'Add to'
    } ${getCartTerm()} ${Service.commafyNumber(
      webProps?.totalPrice?.(),
      true
    )}`;

    return (
      <View style={ThemeStyle.flex1}>
        {!stocks?.isLoading && isMaxReached && (
          <View style={styles.wrapper}>
            <LimitReached
              maxLimit={maxQty}
              mealPlan={mealPlan}
              selectorValue={totalSelectorValue}
              {...limitReachedProps}
            />
          </View>
        )}
        <View style={styles.wrapperButton}>
          <View
            dataSet={{ media: ids.subWrapperButton }}
            style={styles.subWrapperButton}
          >
            {typeof renderSelector === 'function'
              ? renderSelector(_renderSelector)
              : _renderSelector()}

            <Button
              disabled={webProps.disabled}
              onPress={webProps.addToCartPress}
              dataSet={{ media: ids.addToCartButton }}
              style={{
                ...styles.addToCartButton,
                ...{
                  border: store_theme['button_color'] || ThemeColor.green,
                  backgroundColor:
                    store_theme['button_color'] || ThemeColor.green,
                },
              }}
              plain
            >
              {webProps.isAvailable ? (
                <Text
                  category="c1"
                  status="control"
                  style={{
                    color: store_theme['button_text_color'] || ThemeColor.white,
                  }}
                >
                  {buttonText}
                </Text>
              ) : (
                <ActivityIndicator color={ThemeColor.white} size="small" />
              )}
            </Button>
          </View>
        </View>
      </View>
    );
  }

  return (
    <Fragment>
      {typeof renderSelector === 'function'
        ? renderSelector(_renderSelector)
        : _renderSelector()}

      {!stocks?.isLoading && isMaxReached && (
        <View style={[ThemeStyle.fitWidthContainer, ThemeStyle.spacingBottom]}>
          <LimitReached
            maxLimit={maxQty}
            mealPlan={mealPlan}
            selectorValue={totalSelectorValue}
            {...limitReachedProps}
          />
        </View>
      )}
    </Fragment>
  );
}

QuantitySelector.propTypes = {
  isUpdate: PropTypes.bool,
  renderSelector: PropTypes.func,
  onLimitReached: PropTypes.func,
};

export default QuantitySelector;

const { ids, styles } = StyleSheet.create({
  wrapper: {
    ...ThemeStyle.spacingLeft,
    ...ThemeStyle.spacingRight,
    ...ThemeStyle.spacingTopMedium,
    [`@media ${BreakPoints.xs}`]: {
      ...ThemeStyle.spacingBottomMedium,
    },
  },
  wrapperButton: { ...ThemeStyle.flex1, ...ThemeStyle.alignSelfCenter },
  subWrapperButton: {
    ...ThemeStyle.spacingTop,
    width: 350,
    flexDirection: 'row',
    justifyContent: 'space-between',

    [`@media ${BreakPoints.xs}`]: {
      paddingBottom: 20,
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
  },

  addToCartButton: {
    padding: 10,
    width: 200,
    height: 40,
    borderRadius: 120,
    borderWidth: 1,
    alignItems: 'center',
    justifyContent: 'center',
    [`@media ${BreakPoints.xs}`]: {
      ...ThemeStyle.spacingTopMedium,
      width: 'auto',
    },
  },
});
