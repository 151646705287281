import { RefObject, useCallback, useEffect, useMemo, useRef } from 'react';

import { ModalRef, ModalProps } from '../types/types';
import { ModalManager } from '../hooks/useModalManager';
import useModalAction from '../hooks/useModalAction';
import { useModalPayload, useModalRef } from '../hooks/provider';
import { useProviderContext } from '../hooks/context';
import useModalEventManager, {
  actionModalEventManager,
} from '../hooks/useModalEventManager';

import PopupModal from '../../PopupModal';

const ModalPrompt = ({
  id,
  title,
  baseWidth,
  children,
  hasHeader = true,
  hasHeaderLine = true,
  closeOnDocumentClick = false,
  dataSet,
  mainContentWrapperStyle,
  childContentContainerStyle,
  headerStyle,
  scrollEnabled,
  footer,
  onCloseButtonClick,
  onBeforeShow,
  onBeforeClose,
  onClose,
}: ModalProps) => {
  const modalId = id;
  const payload = useModalPayload();
  const internalEventManager = useMemo(() => useModalEventManager(), []);

  const { visible, setVisible } = useModalAction({
    id: modalId,
    onHide: (data: any) => {
      hideModal(data);
    },
    onBeforeShow: (data: any) => {
      onBeforeShow?.(data as never);
    },
    onContextUpdate: () => {
      if (modalId) {
        ModalManager.add(modalId, currentContext);
        ModalManager.registerRef(modalId, currentContext, {
          current: getRef(),
        } as RefObject<ModalRef>);
      }
    },
  });

  const currentContext = useProviderContext();
  const modalRef = useModalRef();

  const payloadRef = useRef(payload);

  const getRef = useCallback(
    (): ModalRef => ({
      show: () => {
        onBeforeShow?.();
        setVisible(true);
      },
      hide: (data: any) => {
        hideModal(data);
      },
      setModalVisible: (_visible?: boolean) => {
        if (_visible) {
          setVisible(true);
        } else {
          hideModal();
        }
      },
      isOpen: () => visible,
    }),
    [internalEventManager, onBeforeShow, setVisible, visible]
  );

  const hideModal = useCallback(
    (data?: any) => {
      if (modalId) {
        const returnValue = (data || payloadRef.current || data) as never;
        setVisible(false);
        onBeforeClose?.(returnValue);
        ModalManager.remove(modalId, currentContext);
        actionModalEventManager.publish(
          `onclose_${modalId}`,
          data || payloadRef.current || data,
          currentContext
        );
      }
    },
    [closeOnDocumentClick, setVisible]
  );

  useEffect(() => {
    if (!visible) onClose?.();
  }, [visible]);

  useEffect(() => {
    if (modalId) {
      ModalManager.registerRef(modalId, currentContext, {
        current: getRef(),
      } as RefObject<ModalRef>);
    }
    modalRef.current = getRef();
  }, [currentContext, getRef, modalId, modalRef]);

  return (
    <PopupModal
      isOpen={visible}
      onCloseButtonClick={onCloseButtonClick}
      onClosed={hideModal}
      baseWidth={baseWidth}
      closeOnDocumentClick={closeOnDocumentClick}
      hasHeader={hasHeader}
      headerStyle={headerStyle}
      hasHeaderLine={hasHeaderLine}
      title={title}
      children={children}
      dataSet={dataSet}
      mainContentWrapperStyle={mainContentWrapperStyle}
      childContentContainerStyle={childContentContainerStyle}
      scrollEnabled={scrollEnabled}
      footer={footer}
    />
  );
};

export default ModalPrompt;
