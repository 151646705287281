import React, { Fragment, useEffect, useRef } from 'react';
import { View, TouchableOpacity, Linking } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import StyleSheet from 'react-native-media-query';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty as lodashIsEmpty, find as lodashFind } from 'lodash';
import { Card, Icon, Text, useTheme } from '@ui-kitten/components';

import constants from '../../../Config/constants';

import { updateCheckoutDetails } from '../../../RTK/checkout';
import { checkout } from '../../../RTK/defaultValues';

import ScreenSize from '../../../Helper/ScreenSize';
import Service from '../../../Service';

import withFavourite from '../../../Hooks/withFavourite';
import useMealPlan from '../../../Hooks/useMealPlan';

import ThemeStyle from '../../../Theme/styles';
import ThemeColor from '../../../Theme/colors';
import BreakPoints from '../../../Theme/styles/breakpoints';

import Button from '../../../Components/Button';
import { RectangleLoader } from '../../../Components/Loader';

import useStoreDescriptionController from './controller/StoreDescription.controller';
import useDetailsController from '../controller/details';

import routeList from '../../../Routes/list';

import { formatDistance } from '../../../Helper';

import { checkoutOrderTypeSelector } from '../../../RTK/checkout/selectors';
import { shopSelector } from '../../../RTK/shop/selectors';
import OrderTypeNote from '../OrderTypeNote';

import RatingText from '../../../Components/RatingText';

import useModalPrompt from '../../../Components/Web/Modal/ModalPrompt/hooks/useModalPrompt';
import { MODALPROMPT } from '../../../Components/Web/Modal/ModalPrompt/config';

import { RAZZLE_BUILD_MODE } from '@env';

const isBranded = RAZZLE_BUILD_MODE === 'branded';
const StoreDescription = ({ store_id, distance, seeReviews, ...props }) => {
  const { isSavedToFavourite, toggleFavourite } = props;
  const store_theme = useTheme();

  const dispatch = useDispatch();
  const navigation = useNavigation();

  const orderTypeHasBeenSet = useRef();

  const shopData = useSelector(shopSelector);

  const { hasRating, getStoreStatus } = useDetailsController({
    additionalCondition: true,
    isModal: false,
  });

  const { showModalPrompt } = useModalPrompt();
  const { itemRequired } = useMealPlan();

  const { xs } = ScreenSize();

  const selectedOrderType = useSelector((state) =>
    checkoutOrderTypeSelector(state, shopData?.id, true)
  );

  let paramOrderType = '';

  // Check if running in web
  if (constants.isWeb) {
    if (isBranded) {
      const params = new URLSearchParams(document?.location?.search); // check url params for order_type
      paramOrderType = params.get('order_type') || paramOrderType; // default order type as string
    }
  }

  const searchTagsFilter = useSelector((state) => state.filter.search.tags);
  const homeTagsFilter = useSelector((state) => state.filter.home.tags);
  const homeOrderTypeFilter = lodashFind(homeTagsFilter, {
    paramName: 'order_type',
  });

  useEffect(() => {
    if (!lodashIsEmpty(shopData) && !orderTypeHasBeenSet.current) {
      // check for previous screen only for search
      // because aside from search, other screen to access store page must use the homepage tag filter
      const routes = navigation.getState().routes; // get routes
      const prevRoutes = routes[routes.length - 2]; // get previous routes
      const isPreviousRouteSearch =
        prevRoutes?.name === routeList.SEARCH ? true : false; // is previous page is search ?
      const homeOrderTypeFilter = lodashFind(homeTagsFilter, {
        paramName: 'order_type',
      }); // get tag filter on homepage
      const searchOrderTypeFilter = lodashFind(searchTagsFilter, {
        paramName: 'order_type',
      }); // get tag filter on search page
      const tagFilter = isPreviousRouteSearch
        ? searchOrderTypeFilter
        : homeOrderTypeFilter; // if previous route is search: use the search tag filter, else home tag filter
      if (!lodashIsEmpty(tagFilter)) {
        _changeOrderTypeToSelectedTagFilter(tagFilter);
      }
      orderTypeHasBeenSet.current = true; // for flag, for this not to be called every change of menu (meal plan to non meal plan vice versa)
    }
    if (!selectedOrderType?.value) {
      _changeOrderTypeToSelectedTagFilter(homeOrderTypeFilter);
    }
  }, [shopData]);

  const _changeOrderTypeToSelectedTagFilter = (orderTypeFilter) => {
    const orderType = orderTypeFilter?.values[0].value || paramOrderType;
    const type = lodashFind(constants.ORDER_TYPE_DATA, { value: orderType });
    if (type) {
      dispatch(
        updateCheckoutDetails({
          store_id: store_id,
          keyToUpdate: checkout.keys.ORDER_TYPE,
          keyValue: type,
        })
      );
    }
  };

  const moreInfoPress = () =>
    showModalPrompt(MODALPROMPT.storeInformation, { shopName: shopData?.name });

  const StoreIndicator = () => {
    const { status, statusText, statusDescription } = getStoreStatus();

    return (
      <View style={styles.largeStoreDescriptionRow}>
        <View
          dataSet={{ media: ids.storeDescriptionRowColumn }}
          style={styles.storeDescriptionRowColumn}
        >
          <View style={styles.storeDescriptionRowFlexBase}>
            <View style={{ marginRight: 5, justifyContent: 'center' }}>
              <Icon
                name={'clock-time-four-outline'}
                fill={store_theme['icon_color'] || ThemeColor.green}
                pack="material"
                style={styles.time_icon}
              />
            </View>

            <View style={{ justifyContent: 'center' }}>
              <Text category="p2" status={status}>
                {statusText}
              </Text>
            </View>

            <View style={{ justifyContent: 'center' }}>
              <Icon
                name={'dot-single'}
                fill={ThemeColor.darkGray}
                pack="entypo"
                style={styles.control_icon}
              />
            </View>

            <View style={{ justifyContent: 'center' }}>
              <Text category="p2" status="primary">
                {statusDescription}
              </Text>
            </View>
          </View>
        </View>
      </View>
    );
  };

  const _onButtonPressed = () => {
    const url = Service.getStoreMapUrl(
      shopData.location,
      shopData?.geocode_location?.lat,
      shopData?.geocode_location?.lng
    );
    Linking.openURL(url);
  };

  if (lodashIsEmpty(shopData)) {
    return (
      <View
        dataSet={{ media: ids.loadingSkeletonContainer }}
        style={styles.loadingSkeletonContainer}
      >
        <View style={ThemeStyle.spacingTopMedium}>
          <RectangleLoader width={250} height={30} />
        </View>
        <View style={ThemeStyle.flexDirectionRowSpaceBetween}>
          <View style={ThemeStyle.spacingTopMedium}>
            <RectangleLoader width={150} height={20} />
          </View>
          <View style={ThemeStyle.spacingTopMedium}>
            <RectangleLoader width={100} height={20} />
          </View>
        </View>

        <View style={ThemeStyle.flexDirectionRowSpaceBetween}>
          <View>
            <View style={ThemeStyle.spacingTopMedium}>
              <RectangleLoader width={150} height={20} />
            </View>
            <View style={ThemeStyle.spacingTopMedium}>
              <RectangleLoader width={150} height={20} />
            </View>
          </View>
          <View style={styles.loading_column_2_container}>
            <View style={ThemeStyle.spacingTopMedium}>
              <RectangleLoader width={130} height={25} />
            </View>
            <View style={ThemeStyle.spacingTopMedium}>
              <RectangleLoader width={130} height={25} />
            </View>
          </View>
        </View>
      </View>
    );
  }
  return (
    <View style={styles.wrapper}>
      {/*
          store name section
          */}
      <View
        dataSet={{ media: ids.storeDescription }}
        style={styles.storeDescription}
      >
        {!Boolean(shopData?.is_accepting_in_advanced_orders) && (
          <Card
            status="danger"
            style={styles.notAcceptingAdvanceOrderContainer}
          >
            <Text category="s2" style={styles.notAcceptingOrderTxt}>
              This store is not accepting advance orders.
            </Text>
          </Card>
        )}

        <Text category="h5">{shopData?.name}</Text>
        {!!shopData.store_tags && (
          <Text category="p2" status="primary" style={ThemeStyle.capitalize}>
            {shopData.store_tags.join(' · ')}
          </Text>
        )}
        <View
          dataSet={{ media: ids.storeDescriptionRow }}
          style={styles.storeDescriptionRow}
        >
          <View
            style={[
              ThemeStyle.spacingBottomSmall,
              isBranded && { width: '100%' },
            ]}
          >
            <View style={styles.storeDescriptionRowColumn}>
              <View style={styles.storeDescriptionRowFlexBase}>
                {!isBranded && (
                  <Fragment>
                    <View style={ThemeStyle.justifyContentCenter}>
                      <RatingText
                        storeId={store_id}
                        type={2}
                        category="p2"
                        status="primary"
                      />
                    </View>
                    {hasRating && (
                      <Icon
                        name={'dot-single'}
                        fill={ThemeColor.darkGray}
                        pack="entypo"
                        style={styles.control_icon}
                      />
                    )}
                  </Fragment>
                )}
                {(typeof distance === 'number' ||
                  typeof distance === 'string') && (
                  <Fragment>
                    <View style={ThemeStyle.justifyContentCenter}>
                      <Text category="p2" status="primary">
                        {`${formatDistance(distance)} away`}
                      </Text>
                    </View>
                    {!isBranded && hasRating && (
                      <Icon
                        name={'dot-single'}
                        fill={ThemeColor.darkGray}
                        pack="entypo"
                        style={styles.control_icon}
                      />
                    )}
                  </Fragment>
                )}
                {!isBranded && hasRating && (
                  <View style={styles.labelContainer}>
                    <TouchableOpacity onPress={seeReviews}>
                      <Text category="p2" status="success">
                        See reviews
                      </Text>
                    </TouchableOpacity>
                  </View>
                )}
                {isBranded && (
                  <View style={{ position: 'absolute', right: 0 }}>
                    <Button
                      testID="more_info_btn"
                      onPress={moreInfoPress}
                      style={styles.more_info_button}
                      plain
                    >
                      <Icon
                        name={'storefront-outline'}
                        fill={store_theme['icon_color'] || ThemeColor.green}
                        pack="material"
                        style={styles.control_icon}
                      />

                      <View
                        dataSet={{ media: ids.storeInfoLabel }}
                        style={styles.storeInfoLabel}
                      >
                        <Text category="c1">Store Info</Text>
                      </View>
                    </Button>
                  </View>
                )}
              </View>
            </View>

            <StoreIndicator />
          </View>
          {!isBranded && (
            <View
              style={{
                ...ThemeStyle.flexDirectionRowSpaceBetween,
                columnGap: 10,
              }}
            >
              <Button
                testID="save_btn"
                style={styles.more_info_button}
                onPress={toggleFavourite({ id: store_id })}
                plain
              >
                <Icon
                  name={isSavedToFavourite(store_id) ? 'heart' : 'heart-o'}
                  fill={ThemeColor.green}
                  pack="fa"
                  style={styles.control_icon}
                />
                <View style={{ marginLeft: 10, alignItems: 'center' }}>
                  <Text category="c1">
                    {isSavedToFavourite(store_id)
                      ? 'Remove from My Favorites'
                      : 'Add to My Favorites'}
                  </Text>
                </View>
              </Button>

              <Button
                testID="more_info_btn"
                onPress={moreInfoPress}
                style={styles.more_info_button}
                plain
              >
                <Icon
                  name={'storefront-outline'}
                  fill={store_theme['icon_color'] || ThemeColor.green}
                  pack="material"
                  style={styles.control_icon}
                />

                <View
                  dataSet={{ media: ids.storeInfoLabel }}
                  style={styles.storeInfoLabel}
                >
                  <Text category="c1">Store Info</Text>
                </View>
              </Button>
            </View>
          )}
        </View>

        <OrderTypeNote
          mealPlanRequiredItem={itemRequired}
          storeAddress={shopData.location}
          type={selectedOrderType?.value || paramOrderType}
          goToLocation={_onButtonPressed}
        />
      </View>
    </View>
  );
};

const { ids, styles } = StyleSheet.create({
  contentContainer: [
    ThemeStyle.spacingTopMedium,
    ThemeStyle.spacingBottomMedium,
  ],
  selectionContainer: {
    ...ThemeStyle.pageHorizontalSpacing,
    ...ThemeStyle.pageVerticalSpacing,
    backgroundColor: ThemeColor.gray,
    borderRadius: 10,
  },
  active: {
    backgroundColor: ThemeColor.green,
  },
  icon: {
    ...ThemeStyle.spacingRightMedium,
    width: 20,
    height: 20,
  },

  wrapper: {
    flex: 1,
  },
  storeDescription: {
    [`@media ${BreakPoints.xs}`]: {
      paddingHorizontal: 20,
    },
    [`@media ${BreakPoints.sm}`]: {
      paddingLeft: 20,
      marginHorizontal: 50,
    },
  },

  storeDescriptionRow: {
    alignItems: 'center',
    justifyContent: 'space-between',
    flexShrink: 1,
    flexBasis: 'auto',
    [`@media ${BreakPoints.xs}`]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    [`@media ${BreakPoints.sm}`]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    [`@media ${BreakPoints.md}`]: {
      marginTop: 5,
      flexDirection: 'row',
    },
    [`@media ${BreakPoints.lg}`]: {
      marginTop: 5,
      flexDirection: 'row',
    },
    [`@media ${BreakPoints.xl}`]: {
      marginTop: 5,
      flexDirection: 'row',
    },
    [`@media ${BreakPoints.xxl}`]: {
      marginTop: 5,
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
  },

  storeDescriptionRowColumn: {
    flexShrink: 1,
    flexBasis: 'auto',
    justifyContent: 'space-between',
    [`@media ${BreakPoints.xs}`]: {
      flex: 1,
      flexDirection: 'column',

      justifyContent: 'flex-start',
    },
    [`@media ${BreakPoints.sm}`]: {
      flex: 1,
      flexDirection: 'column',

      justifyContent: 'flex-start',
    },
    [`@media ${BreakPoints.md}`]: {
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    [`@media ${BreakPoints.lg}`]: {
      marginTop: 5,
      flexDirection: 'row',
    },
    [`@media ${BreakPoints.xl}`]: {
      marginTop: 5,
      flexDirection: 'row',
    },
    [`@media ${BreakPoints.xxl}`]: {
      marginTop: 5,
      flexDirection: 'row',
    },
  },
  storeInfoLabel: {
    alignItems: 'center',
    [`@media ${BreakPoints.xs}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.sm}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.md}`]: {
      display: 'flex',
    },
    [`@media ${BreakPoints.lg}`]: {
      display: 'flex',
    },
    [`@media ${BreakPoints.xl}`]: {
      display: 'flex',
    },
    [`@media ${BreakPoints.xxl}`]: {
      display: 'flex',
    },
  },
  storeTagsTxt: {
    color: ThemeColor.darkGray,
  },
  largeStoreDescriptionRow: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    flexShrink: 1,
    flexBasis: 'auto',
  },

  storeDescriptionRowFlexBase: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexShrink: 1,
    flexBasis: 'auto',
    alignItems: 'center',
  },
  statusText: {
    ...ThemeStyle.regular,
    ...ThemeStyle.alignSelfStart,
  },
  statusDescriptionTxt: {
    ...ThemeStyle.regular,
    ...ThemeStyle.alignSelfStart,
    color: ThemeColor.darkGray,
  },
  more_info_button: {
    display: 'flex',
    height: 40,
    borderRadius: 30,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    backgroundColor: ThemeColor.lightGray,
    borderWidth: 1,
    borderColor: ThemeColor.lightGray,
    paddingLeft: 10,
    paddingRight: 10,
    columnGap: 5,
  },
  time_icon: { width: 20, height: 20 },
  control_icon: { width: 20, height: 20 },
  box: {
    borderWidth: 1,
    borderColor: ThemeColor.lightGray,
    borderRadius: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 400,
    padding: 5,
  },

  loadingSkeletonContainer: {
    ...ThemeStyle.spacingTopLarge,
    [`@media ${BreakPoints.xs}`]: {
      paddingHorizontal: 20,
    },
    [`@media ${BreakPoints.sm}`]: {
      paddingLeft: 20,
      marginHorizontal: 50,
    },
  },

  loading_column_2_container: {
    ...ThemeStyle.flexDirectionRowSpaceBetween,
    width: '30%',
  },

  storInfoModalMobileWrapper: {
    width: '90%',
    top: null,
  },
  storInfoModalWrapper: {},
  portrait: {
    marginTop: 30,
  },
  landscape: {
    marginTop: 30,
    height: 250,
  },
  labelGreen: [
    ThemeStyle.regular,
    ThemeStyle.alignSelfStart,
    { color: ThemeColor.green },
  ],
  labelContainer: {
    marginLeft: 5,
    justifyContent: 'center',
  },
  mealPlanTypeHeader: { height: 30, alignItems: 'center' },
  notAcceptingAdvanceOrderContainer: { backgroundColor: ThemeColor.red100 },
  notAcceptingOrderTxt: { textAlign: 'center' },
});

export default withFavourite(StoreDescription);
