import { useTheme } from '@ui-kitten/components';
import { useState } from 'react';

import StoreInformationScreen from '../../../../Screens/StoreInformation';
import ModalPrompt from './ModalPrompt';

const StoreInformation = ({ modalId }: any) => {
  const [data, setData] = useState<any>();
  const store_theme = useTheme();

  return (
    <ModalPrompt
      id={modalId}
      title={data?.shopName}
      hasHeader
      hasHeaderLine
      closeOnDocumentClick={false}
      scrollEnabled={true}
      onBeforeShow={setData}
    >
      <StoreInformationScreen store_theme={store_theme} isModal={true} />
    </ModalPrompt>
  );
};

export default StoreInformation;
