import React, { useState } from 'react';

import { View } from 'react-native';
import StyleSheet from 'react-native-media-query';

import ThemeStyle from '../../../../Theme/styles';
import BreakPoints from '../../../../Theme/styles/breakpoints';

import MealPlanTypes from '../../../MealPlanTypes';

import ModalPrompt from './ModalPrompt';
import useModalPrompt from './hooks/useModalPrompt';

const MealPlanTypeSelector = ({ modalId }) => {
  const { hideModalPrompt } = useModalPrompt();
  const [data, setData] = useState<{ value?: string }>();

  const _onTypeSelection = (data: { value: string }) => {
    hideModalPrompt(modalId, data);
  };

  return (
    <ModalPrompt
      id={modalId}
      baseWidth={400}
      title="Select Meal Plan Type"
      hasHeader={true}
      hasHeaderLine={true}
      closeOnDocumentClick={false}
      scrollEnabled={false}
      onBeforeShow={setData}
    >
      <View style={styles.contentContainer}>
        <View style={ThemeStyle.flexDirectionRowCenterCenter}>
          <MealPlanTypes value={data?.value} onChange={_onTypeSelection} />
        </View>
      </View>
    </ModalPrompt>
  );
};

const { styles } = StyleSheet.create({
  contentContainer: {
    ...ThemeStyle.spacingTopMedium,
    ...ThemeStyle.spacingBottomMedium,
  },
});
export default MealPlanTypeSelector;
