import React from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet } from 'react-native';
import { Card, Icon, Text } from '@ui-kitten/components';
import lodashFind from 'lodash/find';
import lodashIsEmpty from 'lodash/isEmpty';

import { numberToWord, pluralize } from '../../Helper';
import useCart from '../../Hooks/useCart';
import useStorePersist from '../../Hooks/useStorePersist';
import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';

import Button from '../Button';
import constants from '../../Config/constants';
import ScreenSize from '../../Helper/ScreenSize';

const textCategory = 'p2';

function LimitReached({
  maxLimit,
  mealPlan,
  productId,
  selectorValue,
  stocks,
  storeId,
  onReschedule,
}) {
  const { xs } = ScreenSize();
  const { getSavedStore } = useStorePersist();
  const { isCanReschedule } = useCart();

  const isMealPlan = !lodashIsEmpty(mealPlan);
  const storeData = getSavedStore(storeId);
  const showReschedule =
    isCanReschedule(storeId, productId, selectorValue) && !isMealPlan;
  const productData = lodashFind(
    storeData?.menu,
    (menu) => menu.item.id === productId
  );
  const itemCategoryData = lodashFind(storeData?.categories, {
    category_name: stocks?.categoryName,
  });
  const remainingNote = `You can only add ${maxLimit} ${pluralize(
    maxLimit,
    'piece'
  )} of this`;
  const categoryOrItem = stocks?.isCategoryStock ? 'category' : 'item';
  const categoryLimitReached =
    itemCategoryData?.category_limit_per_day > 0 ? 'today' : 'this time slot';
  const itemLimitReached =
    productData?.item_limit_per_day > 0 ? 'today' : 'this time slot';
  const dailyOrTimeslot = stocks?.isCategoryStock
    ? categoryLimitReached
    : itemLimitReached;

  const nonMealPlanMsg = `${remainingNote} ${categoryOrItem} for ${dailyOrTimeslot}.`;
  const mealPlanMsg = `You can only add ${numberToWord(
    mealPlan?.limit || mealPlan?.maxLimit
  )} ${!mealPlan?.isExclusive ? 'non-' : ''}exclusive item to your meal plan.`;
  const btnTxt = 'Do you want to change your order schedule?';

  return (
    <View style={ThemeStyle.fitWidthContainer}>
      <Card style={{ backgroundColor: ThemeColor.gray400 }}>
        <View style={ThemeStyle.flexDirectionRowCenterCenter}>
          <Icon
            name="info-outline"
            fill={ThemeColor.darkGray}
            style={[{ width: 30, height: 30 }, ThemeStyle.spacingRight]}
          />
          <Text category={textCategory} style={ThemeStyle.flex1}>
            {isMealPlan ? mealPlanMsg : nonMealPlanMsg}
          </Text>
        </View>
      </Card>

      {showReschedule && (
        <View
          style={[constants.isWeb && styles.wrapper, ThemeStyle.spacingTop]}
        >
          <Button
            style={
              constants.isWeb && {
                width: !xs ? 350 : 'auto',
              }
            }
            onPress={onReschedule}
            status="success"
          >
            {() => (
              <Text
                category="c1"
                appearance="alternative"
                style={!constants.isWeb && ThemeStyle.bold}
              >
                {btnTxt}
              </Text>
            )}
          </Button>
        </View>
      )}
    </View>
  );
}

LimitReached.propTypes = {
  max: PropTypes.number.isRequired,
  mealPlan: PropTypes.shape({
    limit: PropTypes.number.isRequired,
    maxLimit: PropTypes.number.isRequired,
    isExclusive: PropTypes.bool.isRequired,
  }),
  productId: PropTypes.string.isRequired,
  // selectorValue: the total value of selector + the existing item (e.g: 2 is display in selector but has 3 on existing cart so the value of this props is 2 + 3 = 5)
  selectorValue: PropTypes.number,
  stocks: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    categoryName: PropTypes.string,
    isCategoryStock: PropTypes.bool.isRequired,
    remainingStock: PropTypes.number.isRequired,
    stock: PropTypes.number.isRequired,
  }).isRequired,
  storeId: PropTypes.string.isRequired,
  onReschedule: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  wrapper: [
    ThemeStyle.flexDirectionRowCenterSpaceBetween,
    ThemeStyle.alignSelfCenter,
  ],
});

export default LimitReached;
