import React from 'react';
import { useSelector } from 'react-redux';

import ModalPrompt from './ModalPrompt';
import { FullReviewList } from '../../../../Screens/StoreReviews';
import { shopSelector } from '../../../../RTK/shop/selectors';

const Reviews = ({ modalId }) => {
  const shopData = useSelector(shopSelector);

  return (
    <ModalPrompt
      id={modalId}
      title={'Ratings & Reviews'}
      childContentContainerStyle={{
        paddingHorizontal: 0,
      }}
      headerStyle={{ marginBottom: 0 }}
      hasHeader
      hasHeaderLine
      closeOnDocumentClick={false}
      scrollEnabled={false}
    >
      <FullReviewList
        route={{ params: { id: shopData?.id, name: shopData?.name } }}
      />
    </ModalPrompt>
  );
};

export default Reviews;
