import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Text } from '@ui-kitten/components';

import ModalPrompt from './ModalPrompt';

import { MODALPROMPT } from './config';
import { useState } from 'react';

type Props = {
  modalTitle?: string;
  title: string;
  message: any;
};
const Error = () => {
  const [data, setData] = useState<Props>();
  const message =
    typeof data?.message === 'object'
      ? Object.values(data?.message)
      : data?.message;
  return (
    <ModalPrompt
      id={MODALPROMPT.errorModal}
      baseWidth={400}
      title={data?.modalTitle || ''}
      hasHeader
      onBeforeShow={setData}
    >
      <View style={styles.singleContentContainer}>
        <Text category="p1">{data?.title}</Text>
        <Text category="p2">{message}</Text>
      </View>
    </ModalPrompt>
  );
};

const styles = StyleSheet.create({
  singleContentContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 10,
    paddingHorizontal: 20,
    textAlign: 'center',
  },
});

export default Error;
