import React from 'react';
import { View } from 'react-native';
import { Text } from '@ui-kitten/components';
import lodashPick from 'lodash/pick';

import constants from '../../Config/constants';
import MenuItemHelper from '../../Helper/MenuItem';
import Service from '../../Service';
import ThemeStyle from '../../Theme/styles';

import Button from '../Button';
import Image from '../Image';
import ScaleButtonAnimation from '../Web/Intereaction/ScaleButtonAnimation';

import styles, { subTitleProps } from './styles';
import { itemSmallProps } from './itemPropCheck';

function ItemSmall({ onPress, ...itemInfo }) {
  const { description, discounted_price, image, name, price, regular_price } =
    itemInfo;
  const hasDiscount = MenuItemHelper.hasDiscount(
    lodashPick(itemInfo, ['active_discount', 'discount_type', 'discount_value'])
  );

  const ClickableComponent = constants.isWeb ? ScaleButtonAnimation : Button;

  return (
    <ClickableComponent onPress={onPress} plain>
      <View style={styles.itemSmallContainer}>
        <View style={ThemeStyle.flex1}>
          <Text
            category={constants.isWeb ? 'c1' : 'p2'}
            numberOfLines={Boolean(description) ? 1 : 2}
            style={ThemeStyle.spacingBottomXSmall}
          >
            {name}
          </Text>
          {Boolean(description) && (
            <Text
              category="c2"
              numberOfLines={1}
              style={[ThemeStyle.dimColor, ThemeStyle.spacingBottomXSmall]}
            >
              {description}
            </Text>
          )}
          {Boolean(price) && (
            <View
              style={[ThemeStyle.flexWrap, ThemeStyle.flexDirectionRowCenter]}
            >
              <Text
                {...subTitleProps}
                style={[
                  subTitleProps.style,
                  ThemeStyle.spacingRightSmall,
                  hasDiscount && ThemeStyle.strikethrough,
                ]}
              >
                {Service.commafyNumber(
                  Number(hasDiscount ? regular_price : price),
                  true
                )}
              </Text>
              {hasDiscount && (
                <Text
                  {...subTitleProps}
                  style={[subTitleProps.style, ThemeStyle.noticeText]}
                >
                  {Service.commafyNumber(Number(discounted_price), true)}
                </Text>
              )}
            </View>
          )}
        </View>
        <Image
          source={{ uri: image }}
          style={styles.itemSmallImage}
          resizeMode="cover"
        />
      </View>
    </ClickableComponent>
  );
}

ItemSmall.propTypes = itemSmallProps;

export default ItemSmall;
