import React from 'react';
import { View } from 'react-native';
import { Layout, Text, useTheme } from '@ui-kitten/components';
import { RAZZLE_BUILD_MODE } from '@env';
import lodashIsEmpty from 'lodash/isEmpty';

import EmptyCartIcon from '../../Assets/EmptyCartIcon.svg';
import EmptyCartBrandedIcon from '../../Assets/EmptyCartIcon.branded.svg';
import EmptyFavorites from '../../Assets/EmptyFavorites.svg';

import NoResultFound from '../../Assets/NoResultFound.svg';

import ThemeStyles from '../../Theme/styles';

import { capitalize } from '../../Helper';
import { getCartTerm } from '../../Helper/RemoteConfig';

const isBranded = RAZZLE_BUILD_MODE === 'branded';
const EmptyMessage = {
  cart: "But it doesn't have to be.",
  favorites: "Let's help you look for the perfect restaurant that you'll love.",
  filter: "We couldn't find what you're looking for",
};
const EmptyData = ({
  title = '',
  subtitle = '',
  type = '',
  svgWidth = 180,
  svgHeight = 180,
}) => {
  const EmptyTitle = {
    cart: `${capitalize(getCartTerm())} is empty.`,
    favorites: 'No Favorites to show.',
    filter: 'No results found.',
  };
  const store_theme = useTheme();

  const fillColor = store_theme['icon_color'] || '#2fd1a2';
  const secondary = fillColor + '80' || '#00e8ab';
  const thirdColor = fillColor || '#075';
  const SVG = () => {
    if (type === 'cart') {
      const EmptyComponent = isBranded ? EmptyCartBrandedIcon : EmptyCartIcon;
      const style = isBranded && {
        style: {
          '--primary-color': fillColor,
          '--secondary-color': secondary,
          '--third-color': thirdColor,
        },
      };

      return <EmptyComponent width={svgWidth} height={svgHeight} {...style} />;
    } else if (type === 'favorites') {
      return <EmptyFavorites width={svgWidth} height={svgHeight} />;
    } else if (type === 'filter') {
      return <NoResultFound width={svgWidth} height={svgHeight} />;
    }
    return null;
  };

  return (
    <Layout
      style={[
        ThemeStyles.flex1,
        ThemeStyles.alignItemsCenter,
        ThemeStyles.justifyContentCenter,
        ThemeStyles.alignSelfCenter,
        { backgroundColor: 'transparent' },
      ]}
    >
      {SVG()}

      <View style={[ThemeStyles.spacingTop, ThemeStyles.alignItemsCenter]}>
        <Text category="s1">
          {lodashIsEmpty(title) ? EmptyTitle[type] : title}
        </Text>
        <Text category="p2" status="primary">
          {lodashIsEmpty(subtitle) ? EmptyMessage[type] : subtitle}
        </Text>
      </View>
    </Layout>
  );
};

export default EmptyData;
